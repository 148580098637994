// Overlay

$(function() {
  'use strict';


  // Variables
  var $close = $('.js-overlay-close');


  // Click
  $close.on('click', function(event) {
    $body.removeClass('is-overlayed');
  });

});

/**
 * LOADER.JS
 * Manage loader hide on window load
 ************************************ */

$(function() {
  'use strict';


  /**
   * Variables
   ********************* */
  var $loader = $('.js-loader');


  /**
   * Actions
   ********************* */
  $win.on('load', function() {
    $loader.removeClass('is-active');
  });

});

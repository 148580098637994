// Links
// Add is-hover class to link components for better control on hover effect
$(function() {
  'use strict';
  var $document = $(document);

  function getTransitionEnd () {
    var el = document.createElement('div');

    var transEndEventNames = {
      WebkitTransition : 'webkitTransitionEnd',
      MozTransition    : 'transitionend',
      OTransition      : 'oTransitionEnd otransitionend',
      transition       : 'transitionend'
    };

    for (var name in transEndEventNames) {
      if (el.style[name] !== undefined) {
        return transEndEventNames[name];
      }
    }

    return false;
  }

  var transitionEnd = getTransitionEnd();

  function addListeners ($container) {
    $container.find('.c-link a, a.c-link').on({
      'mouseenter': function () {
        var $this = $(this);
        $this.addClass('is-hover');
        $this.data('transitioning', true);
      },
      'mouseleave': function () {
        var $this = $(this);
        if (transitionEnd && $this.data('transitioning')) {
          $this.one(transitionEnd, function() {
            $this.removeClass('is-hover');
            $this.data('transitioning', true);
          });
        } else {
          $this.removeClass('is-hover');
          $this.data('transitioning', true);
        }
      }
    }).on(transitionEnd, function () {
      $(this).data('transitioning', false);
    });

    $container.find('.c-link--container').on({
      'mouseenter': function () {
        var $this = $(this).find('.a');
        $this.addClass('is-hover');
        $this.data('transitioning', true);
      },
      'mouseleave': function () {
        var $this = $(this).find('.a');
        if (transitionEnd && $this.data('transitioning')) {
          $this.one(transitionEnd, function() {
            $this.removeClass('is-hover');
            $this.data('transitioning', true);
          });
        } else {
          $this.removeClass('is-hover');
          $this.data('transitioning', true);
        }
      }
    }).on(transitionEnd, function () {
      $(this).find('.a').data('transitioning', false);
    });
  }

  addListeners($(document));

  $document.on('dom:updated', function(event, $container) {
    addListeners($container);
  });
});
$(function() {
  'use strict';

  var $inputs = $('.js-space-travelers'),
      travelers = [
        'neil.armstrong@nasa.com',
        'buzz.aldrin@nasa.com',
        'han.solo@starwars.com',
        'leia.organa@starwars.com',
        'james-tiberius.kirk@star-trek.com',
        'spock@star-trek.com',
        'nyota-uhura@star-trek.com',
        'robert.daly@uss-callister.com',
        'jack.o.neil@stargate.com',
        'samantha.carter@stargate.com',
        'ziggy.stardust@spiders-from-mars.com',
        'ellen.ripley@alien.com',
        'buzz.lightyear@toystory.com',
        'eve@wall-e.com',
        'thomas.pesquet@esa.int',
        'elon.musk@spacex.com',
        'florence.porcel@espace.fr'
      ],
      timer;

  if($inputs.length > 0) {

    $inputs.each(function(index, input) {
      var $input = $(input);

      // Reset input placeholder and begin animation
      $input.attr('placeholder', '');
      writeTraveler($input, chooseTraveler());

      var pauseAnimations = false;

      // Pause
      $input.on('writeComplete', function(e) {
        clearTimeout(timer);
        timer = setTimeout(function() {
          if(!pauseAnimations) {
            eraseTraveler($input);
          }
        }, 8000);
      });

      // Restart
      $input.on('eraseComplete', function(e, traveler) {
        clearTimeout(timer);
        if(!pauseAnimations) {
          writeTraveler($input, chooseTraveler(traveler));
        }
      });

      // Stop animations routine on focus
      $input.on('focus', function() {
        clearTimeout(timer);
        pauseAnimations = true;
      });

      // If input is empty restart animations
      $input.on('blur', function() {
        clearTimeout(timer);
        pauseAnimations = false;
        if(!$input.val()) {
          eraseTraveler($input);
        }
      });
    });
  }


  // Choose a random traveler different from previous one
  function chooseTraveler(previous) {
    var traveler = travelers[Math.floor(Math.random() * Math.floor(travelers.length))];
    return traveler === previous ? chooseTraveler(traveler) : traveler;
  }

  // Write animation
  function writeTraveler($input, traveler, letterIndex) {
    if(traveler) {
      letterIndex = letterIndex ? letterIndex : 0;

      timer = setTimeout(function() {
        $input.attr('placeholder', $input.attr('placeholder') + traveler[letterIndex]);
        letterIndex++;

        if(letterIndex < traveler.length) {
          writeTraveler($input, traveler, letterIndex);
        }
        else {
          $input.trigger('writeComplete');
        }
      }, 50);
    }
  }

  // Erase animation
  function eraseTraveler($input, traveler) {
    traveler = traveler ? $input.attr('placeholder') : '';

    setTimeout(function() {
      $input.attr('placeholder', $input.attr('placeholder').slice(0, -1));

      if(!$input.attr('placeholder')) {
        $input.trigger('eraseComplete', [traveler]);
      }
      else {
        eraseTraveler($input, traveler);
      }
    }, 50);
  }
});
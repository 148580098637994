// Gallery

$(function() {
  'use strict';

  $win.load(function() {

    // Variables
    var $gallery   = $('.js-gallery'),
        $container = $('.js-gallery-container'),
        $rack      = $('.js-gallery-rack'),
        $inner     = $('.js-gallery-inner'),
        winWidth   = $win.outerWidth(),
        winHeight  = $win.outerHeight(),
        fullwidth  = 0;

    $rack.each(function() {
      fullwidth += $(this).outerWidth();
    });

    var gap = fullwidth - winWidth;


    // Hover
    $inner.hover(function() {
      $gallery.addClass('is-hovered');
    }, function() {
      $gallery.removeClass('is-hovered');
    })


    // Click
    $inner.on('click', function() {
      var $target = $('#' + $(this).data('target')),
          $slider = $target.find('.js-slick');

      $body.addClass('is-overlayed');
      $('.js-viewer').not($target).hide();
      $target.show();

      $slider.slick({
        arrows: true,
        autoplay: true,
        autoplaySpeed: 3000,
        infinite: false,
        pauseOnFocus: false,
        pauseOnHover: false,
        speed: 1000,
      });
    });


    // Mousemove
    $win.on('mousemove', function(event) {

      if(!$body.hasClass('is-overlayed')) {
        var clientX = event.clientX,
            clientY = event.clientY,
            percent = clientX / winWidth,
            translate = gap * percent;

        $container.css('transform', 'translateX(-' + translate + 'px)');
      }
    });


    // Resize
    $win.on('resize', function() {
      winWidth  = $win.outerWidth();
      winHeight = $win.outerHeight();

      fullwidth = 0;

      $rack.each(function() {
        fullwidth += $(this).outerWidth();
      });

      gap = fullwidth - winWidth;
    });

  });
});

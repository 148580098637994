// Lazyload

$(function () {
  'use strict';

  $win.load(function () {

    // Variables
    var $images = $('.js-lazyload-image');

    $images.each(function () {
      var $container = $(this);
      var $image = $container.find('noscript').text();

      $container.append($image);
    });

  });

});

// Campaign Monitor
// Ajaxification of Campaign Monitor subscribe form, used in footer.php
// Source: https://gist.github.com/jdennes/1155479
$(function () {
  'use strict';

  var $form = $('.js-campaign-monitor');
  var $checkbox = $form.find('.js-campaign-monitor-checkbox');
  var $acceptance = $form.find('.js-campaign-monitor-acceptance');
  var $submit = $form.find('.js-campaign-monitor-submit');
  var state = false;

  var updateSubmit = function (checked) {
    if (checked) {
      $checkbox.removeClass('c-checkbox--invalid');
      $submit.removeClass('is-hidden');
    } else {
      $submit.addClass('is-hidden');
    }
    state = checked;
  };

  $acceptance.on('change', function (event) {
    updateSubmit($acceptance.prop('checked'));
  });

  $form.on('submit', function (e) {
    e.preventDefault();
    if (state) {
      var $form = $(this),
        $input = $form.find('input[type="email"]'),
        $button = $form.find('button[type="submit"]');
      e.preventDefault();
      $form.addClass('is-loading');
      $form.removeClass('is-error is-success');
      $button.attr('disabled', true);
      $.getJSON($form.attr('action') + '?callback=?', $form.serialize(), function (data) {
        if (data.Status === 200) {
          $form.replaceWith('<div class="l-footer__response l-footer__response--success">Merci de vous être inscrit à notre newsletter.<br>Vous devriez recevoir un email de confirmation pour finaliser votre inscription. À très bientôt !</div>');
        } else {
          $form.addClass('is-error');
          $form.replaceWith('<div class="l-footer__response l-footer__response--error">Il semble y avoir une erreur de notre côté.<br>Réessayez en suivant ce lien : <a target="_blank" rel="noopener" class="c-link c-link--auto" href="http://emailing.lunaweb.fr/h/y/CFDB2CE9ECFBC98A">M\'inscrire à la newsletter</a></div>');
        }
        $form.removeClass('is-loading');
        $button.removeAttr('disabled');
      });
    } else {
      $checkbox.addClass('c-checkbox--invalid');
    }
  });

  updateSubmit(state);
});